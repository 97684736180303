import React, { Fragment, useState } from "react";
import "./App.css";

import data from "./data.json";

const cache = {};

function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}
importAll(
  require.context("../public/dishitup", false, /\.(png|PNG|jpe?g|svg)$/)
);

const images = Object.entries(cache).map((module) => module[1]);

console.log(cache);

function App() {
  const { info, skills, tools, education, workExperience, personalProjects } =
    data;
  const [language, setLanguage] = useState("en");

  return (
    <div className="container">
      <header>
        <h1>Daniël Krux</h1>
        <button
          className="language-toggler"
          onClick={() => setLanguage((l) => (l === "en" ? "nl" : "en"))}
        >
          {language === "nl" ? "English version" : "Nederlandse versie"}
        </button>
      </header>
      <main>
        <section className="left">
          <div className="sticky">
            <div>
              <img alt="" className="img" src="./pic.jpg" height="220px" />
            </div>
            <div className="information">
              <p className="look-online">
                {language === "nl"
                  ? "Bekijk ook mijn cv online op danielkrux.dev"
                  : "You can also find this CV online at danielkrux.dev"}
              </p>
              <h2>{info.header[language]}</h2>
              <p>
                <strong>{info.email.label[language]}</strong>
                &nbsp;{info.email.value} <br />
                <strong>{info.phone.label[language]}</strong>&nbsp;
                {info.phone.value} <br />
                <strong>{info.birthdate.label[language]}</strong>
                &nbsp;22/02/1998 <br />
                <span>
                  <strong>{info.address.label[language]}&nbsp;</strong>
                  {info.address.value}
                </span>
              </p>
            </div>
          </div>
        </section>
        <section className="right">
          <div className="inner-container">
            <div className="skills">
              <h2>{skills.header[language]}</h2>
              <h3>{skills.programmingLanguages.label[language]}</h3>
              <p>{skills.programmingLanguages.value}</p>

              <h3>{skills.frameworks.label[language]}</h3>
              <p>{skills.frameworks.value}</p>

              <h3>{skills.reactSpecific.label[language]}</h3>
              <p>{skills.reactSpecific.value}</p>

              <h3>{skills.reactNativeSpecific.label[language]}</h3>
              <p>{skills.reactNativeSpecific.value}</p>

              <h3>{skills.others.label[language]}</h3>
              <p>{skills.others.value}</p>
            </div>
            {tools && (
              <div className="tools">
                <h2>Tools</h2>
                <p>{tools?.value}</p>
              </div>
            )}
          </div>
          <div className="work-experience">
            <h2>{workExperience.header[language]}</h2>
            {workExperience.experiences.map((e, i) => (
              <Fragment key={i}>
                <h4>
                  {e.title[language]} &bull;&nbsp;
                  <a
                    target="_blank"
                    className="header-link"
                    href={e.company.website}
                    rel="noopener noreferrer"
                  >
                    {e.company.name}
                  </a>
                  &nbsp;&bull;&nbsp;
                  {e.start[language] === e.end[language]
                    ? e.start[language]
                    : `${e.start[language]} - ${e.end[language]}`}
                </h4>
                <p>{e.description[language]}</p>
                <ul>
                  {e.projects?.map((p, i) => (
                    <li key={i}>
                      <h5>
                        <a
                          href={p.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {p.title}
                        </a>
                      </h5>
                      <p>{p.description[language]}</p>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ))}
          </div>
          <div className="Personal project">
            <h2>{personalProjects.header[language]}</h2>
            {personalProjects.projects.map((e, i) => (
              <Fragment key={i}>
                <h4>{e.title}&nbsp;</h4>
                <p>{e.description[language]}</p>
                <div className="image-carousel">
                  {images.map((image, i) => (
                    <img key={i} alt="" width={200} src={image} />
                  ))}
                </div>
              </Fragment>
            ))}
          </div>
          <div className="education">
            <h2>{education.header[language]}</h2>
            {education.educations.map((e, i) => (
              <Fragment key={i}>
                <h4>
                  <a
                    href={e.school.website}
                    target="_blank"
                    className="header-link"
                    rel="noopener noreferrer"
                  >
                    {e.title[language]} &bull; {e.school.name}
                  </a>
                </h4>
                <p>
                  {e.start[language]} -{e.end[language]}
                </p>
              </Fragment>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
